.notification-item {
  .notification-item-inner {
    .icon {
      font-size: 35px;
    }
    .right {
      right: 1.5rem;
      position: absolute;
      transition: all .2s;
      .p-button {
        display: none;
      }
      .p-tag {
        width: 1rem;
        height: 1rem;
      }
    }
    &:hover {
      .right {
        .p-button {
          display: initial;
        }
      }
    }
  }
  &.unread {
    .notification-item-inner {
      .icon {
        color: var(--success-color);
      }
    }
  }
}

html.rtl {
  .notification-item {
    .notification-item-inner {
      .right {
        right: initial;
        left: 1.5rem;
      }
    }
  }
}